import { Route } from 'react-router-dom'

// routes
import { PRIVATE_ROUTES } from '../routes'

// modules
import WorkSpace from '../modules/workSpace/workSpace.index'
import Talent from '../modules/talent/talent.index'
import GlobalSetting from '../modules/global-settings'
import MasterDataPanel from '../modules/masterDataSettings/masterDataPanel'
import PermissionRoutes from '../modules/permissionRoutes'
import Files from '../modules/files/files.index'
import TeamMember from '../modules/team'
import Home from '../modules/home/home.index'
import Reports from '../modules/reports'
import Tasks from '../modules/tasks'
import CreateNote from '../modules/notes/createNote.index'
import ProjectNotes from '../modules/notes/notes.index'
import Projects from '../modules/projects/projects.index'
import Users from '../modules/users'
import PermissionFieldModule from '../modules/permissionField'
import GeneralSettings from '../modules/genereal-settings/generalSettings.index'
import RoleModule from '../modules/role'
import StandUps from '../modules/standups'
import Inquiries from '../modules/inquiries'
import Clients from '../modules/clients'
import SprintsSection from '../modules/sprints'

// constants
import { DOMAIN_LIST, SUPER_ADMIN, WORKSPACE_ADMIN } from '../constants/roles'
import ChatModuleComponent from '../modules/chat'
import Recruit from '../modules/recruit'
import Jobs from '../modules/jobs'
import JobApplication from '../modules/jobApplications'

// SUPER ADMIN ROUTE
const SUPER_ADMIN_ROUTE = [
    <Route path={PRIVATE_ROUTES.workSpace} element={<WorkSpace />} />,
    <Route path={PRIVATE_ROUTES.talent} element={<Talent />} />,
    <Route path={PRIVATE_ROUTES.recruit} element={<Recruit />} />,
    <Route path={PRIVATE_ROUTES.inquiries} element={<Inquiries />} />,
    <Route path={PRIVATE_ROUTES.jobs} element={<Jobs />} />,
    <Route
        path={PRIVATE_ROUTES.jobApplication(':id')}
        element={<JobApplication />}
    />,
    <Route path={PRIVATE_ROUTES.chat} element={<ChatModuleComponent />} />,
    <Route path={PRIVATE_ROUTES.inquiries} element={<Clients />} />,
    <Route
        path={PRIVATE_ROUTES.masterData.root(':id')}
        element={<MasterDataPanel />}
    />,
]

// GLOBAL SETTING ROUTE
const GLOBAL_SETTING_ROUTE = (
    <Route
        path={PRIVATE_ROUTES.globalSettings.root}
        element={<GlobalSetting />}
        key={Math.random()}
    />
)

// WORKSPACE ADMIN ROUTE
const WORKSPACE_ADMIN_ROUTE = [
    <Route path={PRIVATE_ROUTES.roleSetting.root} element={<RoleModule />} />,
    <Route
        path={PRIVATE_ROUTES.projectSetting.root}
        element={<GeneralSettings />}
    />,
    <Route
        path={PRIVATE_ROUTES.roleSetting.permissionModule(':id')}
        element={<PermissionFieldModule />}
    />,
    <Route path={PRIVATE_ROUTES.inquiries} element={<Inquiries />} />,
]

// ROUTE LIST WITH SOME ACCESS
const CONDITION_ROUTES = [
    <Route
        path={PRIVATE_ROUTES.projects.root}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.projects.root}>
                <Projects />
            </PermissionRoutes>
        }
    />,
    <Route
        path={PRIVATE_ROUTES.reports}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.reports}>
                <Reports />
            </PermissionRoutes>
        }
    />,
    <Route
        path={PRIVATE_ROUTES.users}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.users}>
                <Users />
            </PermissionRoutes>
        }
    />,
]

// GENERAL ROUTE
const GENERAL_ROUTE = [
    <Route path={PRIVATE_ROUTES.workSpace} element={<WorkSpace />} />,
    <Route path={PRIVATE_ROUTES.overview} element={<Home />} />,
    <Route path={PRIVATE_ROUTES.chat} element={<ChatModuleComponent />} />,
    <Route
        path={PRIVATE_ROUTES.users}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.users}>
                <Users />
            </PermissionRoutes>
        }
    />,
    <Route
        path={PRIVATE_ROUTES.projects.root}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.projects.root}>
                <Projects />
            </PermissionRoutes>
        }
    />,
    <Route
        path={PRIVATE_ROUTES.reports}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.reports}>
                <Reports />
            </PermissionRoutes>
        }
    />,
    <Route
        path={PRIVATE_ROUTES.notes.root}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.notes.root}>
                <ProjectNotes />
            </PermissionRoutes>
        }
    />,
    <Route path={PRIVATE_ROUTES.notes.createNote} element={<CreateNote />} />,
    <Route
        path={PRIVATE_ROUTES.notes.editNote(':id')}
        element={<CreateNote />}
    />,
    <Route
        path={PRIVATE_ROUTES.notes.viewNote(':id')}
        element={<CreateNote />}
    />,
    <Route
        path={PRIVATE_ROUTES.sprints}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.sprints}>
                <SprintsSection />
            </PermissionRoutes>
        }
    />,

    <Route
        path={PRIVATE_ROUTES.tasks}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.tasks}>
                <Tasks />
            </PermissionRoutes>
        }
    />,
    <Route path={PRIVATE_ROUTES.settings} element={<GeneralSettings />} />,
    <Route
        path={PRIVATE_ROUTES.reports}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.reports}>
                <Reports />
            </PermissionRoutes>
        }
    />,
    <Route
        path={PRIVATE_ROUTES.team}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.team}>
                <TeamMember />
            </PermissionRoutes>
        }
    />,
    <Route
        path={PRIVATE_ROUTES.files}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.files}>
                <Files />
            </PermissionRoutes>
        }
    />,
    <Route
        path={PRIVATE_ROUTES.standups}
        element={
            <PermissionRoutes keyPath={PRIVATE_ROUTES.standups}>
                <StandUps />
            </PermissionRoutes>
        }
    />,
]

// CHECK PERMISSION AND ASSIGN ROUTES
export const checkRoutePermission = (userProfile) => {
    if (
        userProfile?.role?.roleName === SUPER_ADMIN &&
        DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain)
    ) {
        return [GLOBAL_SETTING_ROUTE, ...SUPER_ADMIN_ROUTE]
    } else if (
        userProfile?.role?.roleName === WORKSPACE_ADMIN ||
        (userProfile?.role?.roleName === SUPER_ADMIN &&
            !DOMAIN_LIST.includes(userProfile?.currentWorkSpace?.domain))
    ) {
        if (userProfile?.activeProject !== null) {
            return [
                ...WORKSPACE_ADMIN_ROUTE,
                GLOBAL_SETTING_ROUTE,
                ...GENERAL_ROUTE,
            ]
        } else {
            return [
                ...CONDITION_ROUTES,
                ...WORKSPACE_ADMIN_ROUTE,
                GLOBAL_SETTING_ROUTE,
            ]
        }
    } else if (
        userProfile?.role?.roleName !== SUPER_ADMIN &&
        userProfile?.role?.roleName !== WORKSPACE_ADMIN &&
        userProfile?.activeProject !== null
    ) {
        return [...GENERAL_ROUTE]
    }
}
