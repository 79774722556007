import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'

// antd
import { Col, Row, Input, Popover, Tooltip, Menu, Avatar, Image } from 'antd'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'

// components
import Button from '../button/button'
import AvatarComponent from '../avatar'

// assets
import { FilterIcon } from '../../assets/icons'

// styles
import styles from './panel.module.css'

const PanelHeader = ({ props = {} }) => {
    const {
        action,
        count,
        isCount = true,
        search,
        pageLimit,
        onClick,
        skipPage,
        panelName,
        searchValue,
        searchBar,
        refreshPage,
        filterBar,
        filterType,
        clearFilter,
        filterValue,
        changeFilter,
        panelNameSecond,
        panelButtonIcon,
        teamMembersBar,
        teamMembers,
        permissionAdd,
        permissionAddSecond,
        showCalendar,
        calendarShowComponent,
        assigneeFilter,
        assigneeFilterComponent,
        rootSubmenuKeys,
        filterWorkSpaceComponent,
        filterWorkSpace,
        gridView,
        gridViewComponent,
        onClickSecond,
        tooltipAvatar,
        clearData,
        userProfile,
        applyAssigneeFilter,
        selectedTeamMembers,
    } = props

    const dispatch = useDispatch()

    const [openKeys, setOpenKeys] = useState([])

    // SEARCH FUNCTIONALITY
    const searchFn = debounce(async (value) => {
        refreshPage()
        if (isCount) {
            dispatch(
                count({
                    search: value,
                    filter: filterValue,
                    projectId: userProfile?.activeProject?._id,
                })
            )
        }
        dispatch(search(value))
        dispatch(
            action({
                search: value,
                limit: pageLimit,
                skip: skipPage,
                filter: filterValue,
                projectId: userProfile?.activeProject?._id,
            })
        )
    }, 800)

    // const [openKeys, setOpenKeys] = useState(['sub1']);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys)
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
        }
    }

    // FILTER POPOVER CONTENT
    const filterContent = () => {
        return (
            <>
                <Menu
                    items={filterType}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    mode="inline"
                    style={{
                        padding: '0px',
                        marginBottom: '0px',
                    }}
                    selectedKeys={[
                        filterValue?.isActive === true
                            ? 'active'
                            : filterValue?.isActive === false
                            ? 'inActive'
                            : [],
                        filterValue?.isOurUser === true
                            ? 'verified'
                            : filterValue?.isOurUser === false
                            ? 'nonVerified'
                            : [],
                    ]}
                    className="menuPanelRoot"
                    onClick={(menu) => {
                        let dropDownValue
                        if (menu.item.props.name === 'clear') {
                            dispatch(clearFilter())
                            dropDownValue = {}
                        } else {
                            dropDownValue = {
                                ...filterValue,
                                [menu.item.props.name]: menu.item.props.flag,
                            }
                            Object.keys(dropDownValue).forEach((key) => {
                                if (dropDownValue[key] === '') {
                                    delete dropDownValue[key]
                                }
                            })
                            clearData()
                            dispatch(
                                changeFilter({
                                    ...filterValue,
                                    [menu.item.props.name]:
                                        menu.item.props.flag,
                                })
                            )
                        }
                        dispatch(
                            count({
                                search: searchValue,
                                filter: dropDownValue,
                            })
                        )
                        dispatch(
                            action({
                                search: searchValue,
                                limit: pageLimit,
                                skip: skipPage,
                                filter: dropDownValue,
                            })
                        )
                    }}
                    rootClassName={styles.menuPanelHeader}
                />
            </>
        )
    }

    return (
        <Row
            align={'middle'}
            justify={searchBar || showCalendar ? 'space-between' : 'end'}
            style={{
                marginBottom: '37px',
            }}
        >
            {searchBar && (
                <Col span={14}>
                    <Input
                        style={{
                            height: '44px',
                        }}
                        placeholder="Search"
                        onChange={(event) => searchFn(event.target.value)}
                        prefix={
                            <SearchOutlined
                                style={{
                                    fontSize: 16,
                                    marginRight: 5,
                                }}
                            />
                        }
                    />
                </Col>
            )}
            {showCalendar && calendarShowComponent()}
            <Row
                align={'middle'}
                justify={'end'}
                style={{
                    columnGap: '20px',
                }}
            >
                {gridView && gridViewComponent()}
                {filterWorkSpace && filterWorkSpaceComponent()}
                {assigneeFilter && assigneeFilterComponent()}
                {filterBar && (
                    <Col span={1}>
                        <Popover
                            content={filterContent}
                            title=""
                            trigger="click"
                            arrow={false}
                            placement="bottomRight"
                            overlayInnerStyle={{
                                padding: '8px',
                            }}
                        >
                            <Col span={1}>
                                <Tooltip title="Filter">
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <FilterIcon />
                                    </div>
                                </Tooltip>
                            </Col>
                        </Popover>
                    </Col>
                )}
                {teamMembersBar && teamMembers?.length > 0 && (
                    <Avatar.Group
                        maxCount={10}
                        size={32}
                        maxPopoverTrigger="click"
                        style={{
                            columnGap: '7px',
                        }}
                    >
                        {teamMembers?.length > 0 &&
                            teamMembers.map((data) => (
                                <Popover
                                    content={data?.label}
                                    // trigger="hover"
                                    style={{
                                        textTransform: 'capitalize',
                                    }}
                                    key={data?._id}
                                    className={styles.teamAvatar}
                                >
                                    {data?.profilePicture ? (
                                        <div
                                            className={
                                                selectedTeamMembers.includes(
                                                    data?.value
                                                )
                                                    ? styles.avatarRing
                                                    : ''
                                            }
                                            onClick={() => {
                                                applyAssigneeFilter(
                                                    'assignee',
                                                    data?.value
                                                )
                                            }}
                                        >
                                            <Image
                                                src={data?.profilePicture}
                                                height={'32px'}
                                                preview={false}
                                                width={'32px'}
                                                style={{
                                                    borderRadius: '50%',
                                                    position: 'relative',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    applyAssigneeFilter(
                                                        'assignee',
                                                        data?.value
                                                    )
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                selectedTeamMembers.includes(
                                                    data?.value
                                                )
                                                    ? styles.avatarRing
                                                    : ''
                                            }
                                            onClick={() => {
                                                applyAssigneeFilter(
                                                    'assignee',
                                                    data?.value
                                                )
                                            }}
                                        >
                                            <AvatarComponent
                                                props={{
                                                    avatarName: data?.label,
                                                    size: 32,
                                                    avatarData: data,
                                                    tooltipAvatar:
                                                        tooltipAvatar,
                                                    onClick: () =>
                                                        applyAssigneeFilter(
                                                            'assignee',
                                                            data?.value
                                                        ),
                                                }}
                                            />
                                        </div>
                                    )}
                                </Popover>
                            ))}
                    </Avatar.Group>
                )}
                {permissionAddSecond && panelNameSecond && (
                    <Button
                        props={{
                            text: `${panelNameSecond}`,
                            onClick: () => onClickSecond(),
                            style: {
                                marginLeft: panelNameSecond ? '0px' : '16px',
                                textTransform: 'capitalize',
                            },
                            icon: <PlusOutlined />,
                        }}
                    />
                )}
                {permissionAdd && (
                    <Button
                        props={{
                            text: `${panelName}`,
                            onClick: () => onClick(),
                            style: {
                                marginLeft: panelNameSecond ? '0px' : '16px',
                                textTransform: 'capitalize',
                            },
                            icon: panelButtonIcon ? (
                                panelButtonIcon
                            ) : (
                                <PlusOutlined />
                            ),
                        }}
                    />
                )}
            </Row>
        </Row>
    )
}

export default PanelHeader
