import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Resizable } from 're-resizable'
import Draggable from 'react-draggable'
import { debounce } from 'lodash'

// antd
import {
    Avatar,
    Col,
    Drawer,
    Image,
    Row,
    Skeleton,
    Tooltip,
    Typography,
    message,
    theme,
} from 'antd'

import {
    CheckOutlined,
    UserOutlined,
    BugOutlined,
    ProjectOutlined,
} from '@ant-design/icons'

// slices
import {
    roadmapList,
    roadmapState,
    setRoadMapTask,
    setSlotWidth,
    setSlots,
} from './roadmap.slice'
import { loginState } from '../login/login.slice'
import {
    clearTaskFileUpload,
    switchFormModal,
    switchFormModalSubTask,
    taskById,
    taskFileError,
    taskUpdate,
    tasksState,
} from '../tasks/tasks.slice'

// css
import styles from './roadmap.module.css'

import dayjs from 'dayjs'
import { colorTaskRandom } from '../../constants/tasks'
import DrawerTaskForm from '../tasks/taskForm.index'
import { teamState } from '../team/team.slice'

const RoadMap = ({ props }) => {
    const { tabName } = props

    const dispatch = useDispatch()

    const { Text } = Typography

    const { useToken } = theme
    const { token } = useToken()

    const { userProfile } = useSelector(loginState)
    const {
        roadmapTasks,
        slots,
        slotWidth,
        selectedFormat,
        skipPage,
        pageLimit,
        status,
    } = useSelector(roadmapState)
    const {
        teamList,
        pageLimit: teamPageLimit,
        skipPage: teamSkipPage,
    } = useSelector(teamState)
    const { filterDropDown: taskFilterDropdown, formModal } =
        useSelector(tasksState)

    const [pointerEvent, setPointerEvent] = useState(true)
    const [filteredAssignees, setFilteredAssignees] = useState([])

    const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
    dayjs.extend(isSameOrBefore)

    // CREATE SLOTS CALENDAR DATE WISE
    const createSlots = useCallback(
        (startDate, endDate) => {
            let slots = []
            // let now = dayjs(startDate).startOf(selectedFormat).clone()
            let now = dayjs(startDate).clone()

            while (now.isSameOrBefore(dayjs(endDate).clone())) {
                slots.push(now.format('MMM, DD'))
                now = now.add(1, 'days')
            }
            dispatch(setSlots(slots))
        },
        [selectedFormat, dispatch]
    )

    // CHECK FOR LAZY LOADING IS_NEXT
    useEffect(() => {
        if (teamList?.length > 0) {
            let array = []
            teamList?.length > 0 &&
                teamList.forEach((data) => {
                    // if (userProfile?._id !== data?._id) {
                    array = [
                        ...array,
                        {
                            label: data?.name,
                            value: data?._id,
                            profilePicture: data?.profilePicture,
                        },
                    ]
                    // }
                })

            setFilteredAssignees(array)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    // GET ROADMAP TASKS
    const getRoadMapTasks = () => {
        if (tabName === 'roadMap')
            dispatch(
                roadmapList({
                    skip: skipPage,
                    limit: pageLimit,
                    projectId: userProfile?.activeProject?._id,
                    filter: {
                        // sprintId: JSON.parse(localStorage.getItem('currentSprint'))
                        //     ?.id,
                        sprintId: taskFilterDropdown?.sprintId,
                    },
                })
            )
    }

    // CALCULATE TIMELOG
    const calculateTime = (estimation, totalLog) => {
        let newEstimation = estimation * 60

        if (totalLog === 0 && newEstimation === 0) {
            return `0%`
        } else if (totalLog < newEstimation) {
            const percentage = parseInt((totalLog / newEstimation) * 100)
            return `${percentage}%`
        } else if (totalLog > newEstimation) {
            const totalSeconds = totalLog - newEstimation // Example total number of seconds
            const hours = Math.floor(totalSeconds / 3600)
            const remainingSecondsAfterHours = totalSeconds % 3600
            const minutes = Math.floor(remainingSecondsAfterHours / 60)
            const remainingSeconds = Math.floor(remainingSecondsAfterHours % 60)

            const newHour = hours > 0 ? `${hours}h` : ''
            const newMinutes = minutes > 0 ? `${minutes}m` : ''
            const newSeconds =
                remainingSeconds > 0 ? `${remainingSeconds}s` : ''

            return `${newHour}${newMinutes}${newSeconds} overLogged`
        } else if (totalLog === newEstimation) {
            return `100%`
        }
    }

    useEffect(() => {
        // createSlots(dayjs())
        getRoadMapTasks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabName, taskFilterDropdown])

    useEffect(() => {
        if (roadmapTasks?.[0]?.projectId?.start) {
            const startDate = dayjs(roadmapTasks[0].projectId.start) // Convert the timestamp to dayjs object
            const endDate = dayjs(roadmapTasks[0].projectId.end)
            createSlots(startDate, endDate)
        }
    }, [roadmapTasks, createSlots])

    // SET CALENDAR SLOTS WIDTH
    useEffect(() => {
        const interval = setInterval(() => {
            const bounds = document
                .getElementsByClassName('slots')[0]
                ?.getBoundingClientRect()

            if (bounds) {
                dispatch(setSlotWidth(bounds.width + 8))
                clearInterval(interval)
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slots])

    // TASK UPDATE ON DRAG / RESIZE
    const taskUpdateDrag = debounce(async (id, start, end) => {
        const result = await dispatch(
            taskUpdate({
                updateTask: {
                    start: start,
                    end: end,
                },
                _id: id,
            })
        )

        const data = result?.payload?.data
        if (data) {
            const { success, message: checkMessage } = data
            if (success) {
                setPointerEvent(true)
                // getRoadMapTasks()
                message.success(checkMessage)
            } else {
                setPointerEvent(true)
            }
        }
    }, 1500)

    window.onresize = function () {
        createSlots(dayjs())
    }

    // SET ROADMAP TASKS
    const setRoadMapTasks = (data, isParent, isoString, endDateString) => {
        const roadmapNewTasks = structuredClone(roadmapTasks)
        if (isParent) {
            const changeTask = roadmapNewTasks.map((task) => {
                if (task?._id === data?._id) {
                    return {
                        ...task,
                        start: isoString,
                        end: endDateString,
                    }
                } else {
                    return task
                }
            })
            setPointerEvent(false)
            dispatch(setRoadMapTask(changeTask))
        } else {
            const changeChildTask = roadmapNewTasks.map((task) => {
                let newItem = { ...task }
                // Check if the item has a subTasks array
                if (newItem.subTasks && newItem.subTasks.length > 0) {
                    // Find the subtask with the specified _id
                    let subTaskIndex = newItem.subTasks.findIndex(
                        (subTask) => subTask._id === data?._id
                    )

                    // If the subtask is found, update its end date
                    if (subTaskIndex !== -1) {
                        // Clone the subtask to avoid modifying it directly
                        let updatedSubTask = {
                            ...newItem.subTasks[subTaskIndex],
                        }

                        // Update the end date
                        updatedSubTask.start = isoString
                        updatedSubTask.end = endDateString

                        // Replace the old subtask with the updated one
                        newItem.subTasks[subTaskIndex] = updatedSubTask
                    }
                }

                return newItem
            })
            setPointerEvent(false)
            dispatch(setRoadMapTask(changeChildTask))
        }
    }

    // ROADMAP DRAGGABLE COMPONENT
    // const RoadmapDraggable = ({ data, keyIndex, isParent }) => {
    //     return (
    //         <>
    //             <Draggable
    //                 axis={'x'}
    //                 key={keyIndex}
    //                 disabled={pointerEvent === false}
    //                 grid={[slotWidth]}
    //                 handle={'.timeline'}
    //                 onStop={(e, dragData) => {
    //                     // END DATE CALCULATION
    //                     const width = e.target.getBoundingClientRect().width
    //                     const slotDate = width / slotWidth
    //                     const endDate = data?.start?.split('T')[0]
    //                     let endNewDate = new Date(endDate)

    //                     endNewDate.setDate(
    //                         endNewDate.getDate() + parseInt(slotDate - 1)
    //                     )

    //                     let endDateString = endNewDate.toISOString()

    //                     // START DATE CALCULATION
    //                     const dateStart = dragData?.x / slotWidth
    //                     const currentYear = new Date().getFullYear()

    //                     const dateString = `${slots[dateStart]} ` + currentYear
    //                     const dateObject = new Date(dateString).setHours(
    //                         23,
    //                         59,
    //                         59,
    //                         999
    //                     )

    //                     const timestamp = dateObject
    //                     const date = new Date(timestamp)
    //                     const isoString = date.toISOString()

    //                     // SET ROADMAP TASK START END
    //                     setRoadMapTasks(
    //                         data,
    //                         isParent,
    //                         isoString,
    //                         endDateString
    //                     )

    //                     taskUpdateDrag(data?._id, isoString, endDateString)
    //                 }}
    //                 defaultPosition={{
    //                     x:
    //                         parseInt(
    //                             dayjs(data?.start.split('T')[0]).diff(
    //                                 dayjs().startOf('year'),
    //                                 'day'
    //                             )
    //                         ) * slotWidth,
    //                     y: 0,
    //                 }}
    //             >
    //                 <Resizable
    //                     bounds={'parent'}
    //                     grid={[slotWidth]}
    //                     minWidth={slotWidth}
    //                     className={styles.resizable}
    //                     onResizeStop={(e, dir, ref) => {
    //                         const element = ref

    //                         if (element) {
    //                             setPointerEvent(false)
    //                             // END DATE CALCULATION
    //                             const width =
    //                                 element.getBoundingClientRect().width
    //                             const slotDate = width / slotWidth
    //                             const date = data?.start.split('T')[0]
    //                             let initialDate = new Date(date)

    //                             initialDate.setDate(
    //                                 initialDate.getDate() +
    //                                     parseInt(slotDate - 1)
    //                             )

    //                             let resultDateString = initialDate.toISOString()

    //                             // SET ROADMAP TASK START END
    //                             setRoadMapTasks(
    //                                 data,
    //                                 isParent,
    //                                 data?.start,
    //                                 resultDateString
    //                             )

    //                             taskUpdateDrag(
    //                                 data?._id,
    //                                 data?.start,
    //                                 resultDateString
    //                             )
    //                         }
    //                     }}
    //                     enable={{
    //                         right: true,
    //                         left: true,
    //                     }}
    //                     defaultSize={{
    //                         width:
    //                             (parseInt(
    //                                 dayjs(data?.end).diff(
    //                                     dayjs(data?.start),
    //                                     'day'
    //                                 )
    //                             ) +
    //                                 1) *
    //                             slotWidth,
    //                     }}
    //                 >
    //                     <div className={styles.timeline + ' timeline'}>
    //                         <Text
    //                             style={{
    //                                 fontSize: '14px',
    //                                 left: '50px',
    //                                 top: '1px',
    //                                 position: 'absolute',
    //                                 color: token.colorPalette.baseColor.white,
    //                             }}
    //                         >
    //                             {calculateTime(
    //                                 data?.estimation,
    //                                 data?.totalLog
    //                             )}
    //                         </Text>
    //                         <div
    //                             style={{
    //                                 position: 'absolute',
    //                                 left: '-15px',
    //                                 top: '-4px',
    //                             }}
    //                         >
    //                             {data?.assigneeId?.length > 0 ? (
    //                                 data?.assigneeId[0]?.profilePicture
    //                                     ?.length > 0 ? (
    //                                     <Image
    //                                         src={
    //                                             data?.assigneeId[0]
    //                                                 ?.profilePicture
    //                                         }
    //                                         height={'32px'}
    //                                         preview={false}
    //                                         width={'32px'}
    //                                         style={{
    //                                             borderRadius: '50%',
    //                                         }}
    //                                     />
    //                                 ) : (
    //                                     <Avatar
    //                                         size={'default'}
    //                                         style={{
    //                                             backgroundColor: '#f56a00',
    //                                             cursor: 'pointer',
    //                                             fontSize: '11px',
    //                                         }}
    //                                     >
    //                                         {data?.assigneeId[0]?.name[0].toUpperCase()}
    //                                         {data?.assigneeId[0]?.name.split(
    //                                             ' '
    //                                         )[1] !== undefined &&
    //                                             data?.assigneeId[0]?.name
    //                                                 .split(' ')[1][0]
    //                                                 .toUpperCase()}
    //                                     </Avatar>
    //                                 )
    //                             ) : (
    //                                 <Avatar
    //                                     size={'default'}
    //                                     icon={<UserOutlined />}
    //                                 />
    //                             )}
    //                         </div>

    //                         <div
    //                             className={styles.dragger + ' dragger'}
    //                             style={{
    //                                 top: 5,
    //                                 right: 5,
    //                                 width: '5px',
    //                                 height: '60%',
    //                                 borderRadius: 5,
    //                                 position: 'absolute',
    //                                 background: 'rgba(0, 0, 0, 0.2)',
    //                             }}
    //                         />
    //                     </div>
    //                 </Resizable>
    //             </Draggable>
    //         </>
    //     )
    // }

    const RoadmapDraggable = ({ data, keyIndex, isParent }) => {
        return (
            <>
                <Draggable
                    axis={'x'}
                    key={keyIndex}
                    disabled={pointerEvent === false}
                    grid={[slotWidth]}
                    handle={'.timeline'}
                    onStop={(e, dragData) => {
                        const width = e.target.getBoundingClientRect().width
                        const slotDate = width / slotWidth

                        // End Date Calculation
                        const endDate = data?.start?.split('T')[0]
                        let endNewDate = new Date(endDate)
                        endNewDate.setDate(
                            endNewDate.getDate() + parseInt(slotDate - 1)
                        )
                        const endDateString = endNewDate.toISOString()

                        // Start Date Calculation
                        const dateStart = dragData?.x / slotWidth
                        const startDate = dayjs()
                            .startOf('year')
                            .add(dateStart, 'day')
                        const isoString = startDate.toISOString()

                        // Set Roadmap Task Start and End
                        setRoadMapTasks(
                            data,
                            isParent,
                            isoString,
                            endDateString
                        )
                        taskUpdateDrag(data?._id, isoString, endDateString)
                    }}
                    defaultPosition={{
                        // Calculate the position relative to the start of the timeline (start of the year)
                        x:
                            dayjs(data?.start).diff(
                                dayjs(data?.projectId?.start),
                                'day'
                            ) * slotWidth,
                        y: 0,
                    }}
                >
                    <Resizable
                        bounds={'parent'}
                        grid={[slotWidth]}
                        minWidth={slotWidth}
                        className={styles.resizable}
                        onResizeStop={(e, dir, ref) => {
                            const element = ref

                            if (element) {
                                setPointerEvent(false)

                                // Calculate the new width
                                const width =
                                    element.getBoundingClientRect().width
                                const slotDate = width / slotWidth

                                // Parse the current start date
                                const startDate = dayjs(data?.start)

                                // Add the slotDate to the start date to get the new end date
                                const newEndDate = startDate
                                    .add(slotDate - 1, 'day')
                                    .toISOString()

                                // Update the task with new start and end dates
                                setRoadMapTasks(
                                    data,
                                    isParent,
                                    data?.start,
                                    newEndDate
                                )
                                taskUpdateDrag(
                                    data?._id,
                                    data?.start,
                                    newEndDate
                                )
                            }
                        }}
                        enable={{
                            right: true,
                            left: true,
                        }}
                        defaultSize={{
                            width:
                                (dayjs(data?.end).diff(
                                    dayjs(data?.start),
                                    'day'
                                ) +
                                    1) *
                                slotWidth,
                        }}
                    >
                        <div className={styles.timeline + ' timeline'}>
                            <Text
                                style={{
                                    fontSize: '14px',
                                    left: '50px',
                                    top: '1px',
                                    position: 'absolute',
                                    color: token.colorPalette.baseColor.white,
                                }}
                            >
                                {calculateTime(
                                    data?.estimation,
                                    data?.totalLog
                                )}
                            </Text>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: '-15px',
                                    top: '-4px',
                                }}
                            >
                                {data?.assigneeId?.length > 0 ? (
                                    data?.assigneeId[0]?.profilePicture
                                        ?.length > 0 ? (
                                        <Image
                                            src={
                                                data?.assigneeId[0]
                                                    ?.profilePicture
                                            }
                                            height={'32px'}
                                            preview={false}
                                            width={'32px'}
                                            style={{ borderRadius: '50%' }}
                                        />
                                    ) : (
                                        <Avatar
                                            size={'default'}
                                            style={{
                                                backgroundColor: '#f56a00',
                                                cursor: 'pointer',
                                                fontSize: '11px',
                                            }}
                                        >
                                            {data?.assigneeId[0]?.name[0].toUpperCase()}
                                            {data?.assigneeId[0]?.name.split(
                                                ' '
                                            )[1] &&
                                                data?.assigneeId[0]?.name
                                                    .split(' ')[1][0]
                                                    .toUpperCase()}
                                        </Avatar>
                                    )
                                ) : (
                                    <Avatar
                                        size={'default'}
                                        icon={<UserOutlined />}
                                    />
                                )}
                            </div>

                            <div
                                className={styles.dragger + ' dragger'}
                                style={{
                                    top: 5,
                                    right: 5,
                                    width: '5px',
                                    height: '60%',
                                    borderRadius: 5,
                                    position: 'absolute',
                                    background: 'rgba(0, 0, 0, 0.2)',
                                }}
                            />
                        </div>
                    </Resizable>
                </Draggable>
            </>
        )
    }

    const count = 5 // Number of skeletons you want to display

    // VIEW TASK
    async function viewTask(taskID) {
        dispatch(
            switchFormModal({
                open: true,
            })
        )
        const result = await dispatch(taskById({ _id: taskID }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
            } else {
                dispatch(
                    switchFormModal({
                        open: false,
                    })
                )
                message.error('Something went wrong, try again later.')
            }
        }
    }

    // VIEW SUB TASK
    async function viewSubTask(taskID) {
        dispatch(switchFormModalSubTask({ open: true }))
        const result = await dispatch(taskById({ _id: taskID }))
        const data = result?.payload?.data
        if (data) {
            const { success } = data
            if (success) {
            } else {
                dispatch(switchFormModalSubTask({ open: false }))

                message.error('Something went wrong, try again later.')
            }
        }
    }

    return (
        <>
            <Row gutter={6}>
                <Col span={10}>
                    <div style={{ marginTop: 44 }}>
                        {status === 'loading'
                            ? Array.from({ length: count }, (_, index) => (
                                  <Row
                                      key={index}
                                      style={{
                                          minWidth: '100%',
                                          width: '100%',
                                      }}
                                  >
                                      <Skeleton.Input
                                          key={index}
                                          active={true}
                                          size={'default'}
                                          style={{
                                              marginBottom: '6px',
                                              width: '100%',
                                          }}
                                          rootClassName="roadmapSkeleton"
                                      />
                                  </Row>
                              ))
                            : status === 'loaded' &&
                              roadmapTasks?.length > 0 &&
                              roadmapTasks.map((data, index) => (
                                  <>
                                      <Row key={index}>
                                          <div className={styles.container}>
                                              <div
                                                  style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                          'space-between',
                                                      gap: '10px',
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          gap: '10px',
                                                      }}
                                                  >
                                                      <Tooltip
                                                          title={data?.type}
                                                          arrow={false}
                                                      >
                                                          {data?.type ===
                                                          'Bug' ? (
                                                              <div
                                                                  className={
                                                                      styles.taskTypeSection
                                                                  }
                                                                  style={{
                                                                      padding:
                                                                          '1px 3px',
                                                                      backgroundColor:
                                                                          'red',
                                                                  }}
                                                              >
                                                                  <BugOutlined
                                                                      style={{
                                                                          fontSize:
                                                                              '12px',
                                                                          color: 'white',
                                                                      }}
                                                                  />
                                                              </div>
                                                          ) : data?.type ===
                                                            'Task' ? (
                                                              <div
                                                                  className={
                                                                      styles.taskTypeSection
                                                                  }
                                                                  style={{
                                                                      padding:
                                                                          '1px 3px',
                                                                      backgroundColor:
                                                                          token
                                                                              .colorPalette
                                                                              .baseColor
                                                                              .tertiary,
                                                                  }}
                                                              >
                                                                  <CheckOutlined
                                                                      style={{
                                                                          fontSize:
                                                                              '10px',
                                                                          color: 'white',
                                                                      }}
                                                                  />
                                                              </div>
                                                          ) : (
                                                              <div
                                                                  className={
                                                                      styles.taskTypeSection
                                                                  }
                                                                  style={{
                                                                      backgroundColor:
                                                                          '#7a16ff',
                                                                  }}
                                                              >
                                                                  <ProjectOutlined
                                                                      style={{
                                                                          fontSize:
                                                                              '10px',
                                                                          color: 'white',
                                                                      }}
                                                                  />
                                                              </div>
                                                          )}
                                                      </Tooltip>
                                                      {data?.taskKey && (
                                                          <span>
                                                              {data.taskKey}
                                                          </span>
                                                      )}

                                                      <Tooltip
                                                          title={data?.title}
                                                      >
                                                          <div
                                                              style={{
                                                                  cursor: 'pointer',
                                                                  whiteSpace:
                                                                      'nowrap',
                                                                  overflow:
                                                                      'hidden',
                                                                  textOverflow:
                                                                      'ellipsis',
                                                                  maxWidth:
                                                                      '200px', // Adjust the width as needed
                                                              }}
                                                              onClick={() =>
                                                                  viewTask(
                                                                      data._id
                                                                  )
                                                              }
                                                          >
                                                              {data?.title
                                                                  ?.length > 40
                                                                  ? `${data.title.substring(
                                                                        0,
                                                                        40
                                                                    )}...`
                                                                  : data.title}
                                                          </div>
                                                      </Tooltip>
                                                  </div>
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          gap: '10px',
                                                      }}
                                                  >
                                                      <div
                                                          className={
                                                              'sub-title'
                                                          }
                                                          style={{
                                                              padding:
                                                                  '2px 6px',
                                                              borderRadius: 1000,
                                                              fontSize: '12px',
                                                              backgroundColor:
                                                                  data?.status ===
                                                                  'To Do'
                                                                      ? '#d5d5d5'
                                                                      : data?.status ===
                                                                        'Done'
                                                                      ? '#C8E5CE'
                                                                      : colorTaskRandom[
                                                                            (Math.random() *
                                                                                colorTaskRandom.length) |
                                                                                0
                                                                        ],
                                                          }}
                                                      >
                                                          {data?.status}
                                                      </div>
                                                      <div>
                                                          {data?.assigneeId ? (
                                                              <div>
                                                                  <Tooltip
                                                                      title={
                                                                          data
                                                                              ?.assigneeId
                                                                              ?.length >
                                                                          0
                                                                              ? data
                                                                                    ?.assigneeId[0]
                                                                                    ?.name
                                                                              : Object.keys(
                                                                                    data?.assigneeId
                                                                                )
                                                                                    ?.length >
                                                                                0
                                                                              ? data
                                                                                    ?.assigneeId
                                                                                    ?.name
                                                                              : 'Unassigned'
                                                                      }
                                                                  >
                                                                      {data
                                                                          ?.assigneeId
                                                                          ?.length >
                                                                          0 ||
                                                                      Object.keys(
                                                                          data?.assigneeId
                                                                      )
                                                                          ?.length >
                                                                          0 ? (
                                                                          data
                                                                              ?.assigneeId[0]
                                                                              ?.profilePicture ? (
                                                                              <Image
                                                                                  src={
                                                                                      data
                                                                                          ?.assigneeId[0]
                                                                                          ?.profilePicture
                                                                                  }
                                                                                  height={
                                                                                      '24px'
                                                                                  }
                                                                                  width={
                                                                                      '24px'
                                                                                  }
                                                                                  preview={
                                                                                      false
                                                                                  }
                                                                                  style={{
                                                                                      borderRadius:
                                                                                          '50%',
                                                                                  }}
                                                                              />
                                                                          ) : (
                                                                              <Avatar
                                                                                  size={
                                                                                      'small'
                                                                                  }
                                                                                  style={{
                                                                                      cursor: 'pointer',
                                                                                      backgroundColor:
                                                                                          'rgb(0 10 129 / 25%)',
                                                                                      height: '24px',
                                                                                      width: '24px',
                                                                                      minWidth:
                                                                                          '24px',
                                                                                  }}
                                                                                  className="active-tooltip"
                                                                              >
                                                                                  {data
                                                                                      ?.assigneeId
                                                                                      ?.length >
                                                                                      0 &&
                                                                                      data?.assigneeId[0]?.name[0].toUpperCase()}
                                                                                  {data
                                                                                      ?.assigneeId
                                                                                      ?.length >
                                                                                      0 &&
                                                                                      data?.assigneeId[0]?.name.split(
                                                                                          ' '
                                                                                      )[1] !==
                                                                                          undefined &&
                                                                                      data?.assigneeId[0]?.name.split(
                                                                                          ' '
                                                                                      )[1] !==
                                                                                          '' &&
                                                                                      data?.assigneeId[0]?.name
                                                                                          .split(
                                                                                              ' '
                                                                                          )[1][0]
                                                                                          .toUpperCase()}
                                                                              </Avatar>
                                                                          )
                                                                      ) : (
                                                                          <Avatar
                                                                              size={
                                                                                  'small'
                                                                              }
                                                                          >
                                                                              <UserOutlined />
                                                                          </Avatar>
                                                                      )}
                                                                  </Tooltip>
                                                                  {/* </Popover> */}
                                                              </div>
                                                          ) : (
                                                              <Tooltip
                                                                  title={
                                                                      'Unassigned'
                                                                  }
                                                              >
                                                                  <Avatar
                                                                      size={
                                                                          'default'
                                                                      }
                                                                      icon={
                                                                          <UserOutlined />
                                                                      }
                                                                  />
                                                              </Tooltip>
                                                          )}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </Row>
                                      {data?.subTasks?.length > 0 &&
                                          data?.subTasks.map((subTask) => (
                                              <Row
                                                  key={index}
                                                  style={{
                                                      marginLeft: '20px',
                                                  }}
                                              >
                                                  <div
                                                      className={
                                                          styles.container
                                                      }
                                                  >
                                                      <div
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'space-between',
                                                              gap: '10px',
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  display:
                                                                      'flex',
                                                                  alignItems:
                                                                      'center',
                                                                  gap: '10px',
                                                              }}
                                                          >
                                                              <Tooltip
                                                                  title={
                                                                      subTask?.type
                                                                  }
                                                                  arrow={false}
                                                              >
                                                                  {subTask?.type ===
                                                                  'Bug' ? (
                                                                      <div
                                                                          className={
                                                                              styles.taskTypeSection
                                                                          }
                                                                          style={{
                                                                              padding:
                                                                                  '1px 3px',
                                                                              backgroundColor:
                                                                                  'red',
                                                                          }}
                                                                      >
                                                                          <BugOutlined
                                                                              style={{
                                                                                  fontSize:
                                                                                      '12px',
                                                                                  color: 'white',
                                                                              }}
                                                                          />
                                                                      </div>
                                                                  ) : subTask?.type ===
                                                                    'Task' ? (
                                                                      <div
                                                                          className={
                                                                              styles.taskTypeSection
                                                                          }
                                                                          style={{
                                                                              padding:
                                                                                  '1px 3px',
                                                                              backgroundColor:
                                                                                  token
                                                                                      .colorPalette
                                                                                      .baseColor
                                                                                      .tertiary,
                                                                          }}
                                                                      >
                                                                          <CheckOutlined
                                                                              style={{
                                                                                  fontSize:
                                                                                      '10px',
                                                                                  color: 'white',
                                                                              }}
                                                                          />
                                                                      </div>
                                                                  ) : (
                                                                      <div
                                                                          className={
                                                                              styles.taskTypeSection
                                                                          }
                                                                          style={{
                                                                              backgroundColor:
                                                                                  '#7a16ff',
                                                                          }}
                                                                      >
                                                                          <ProjectOutlined
                                                                              style={{
                                                                                  fontSize:
                                                                                      '10px',
                                                                                  color: 'white',
                                                                              }}
                                                                          />
                                                                      </div>
                                                                  )}
                                                              </Tooltip>
                                                              <Tooltip
                                                                  title={
                                                                      subTask?.title
                                                                  }
                                                              >
                                                                  <div
                                                                      style={{
                                                                          cursor: 'pointer',
                                                                          whiteSpace:
                                                                              'nowrap',
                                                                          overflow:
                                                                              'hidden',
                                                                          textOverflow:
                                                                              'ellipsis',
                                                                          maxWidth:
                                                                              '200px', // Adjust the width as needed
                                                                      }}
                                                                      onClick={() =>
                                                                          viewSubTask(
                                                                              subTask._id
                                                                          )
                                                                      }
                                                                  >
                                                                      {subTask
                                                                          ?.title
                                                                          ?.length >
                                                                      40
                                                                          ? `${subTask.title.substring(
                                                                                0,
                                                                                40
                                                                            )}...`
                                                                          : subTask.title}
                                                                  </div>
                                                              </Tooltip>
                                                          </div>
                                                          <div
                                                              style={{
                                                                  display:
                                                                      'flex',
                                                                  alignItems:
                                                                      'center',
                                                                  gap: '10px',
                                                              }}
                                                          >
                                                              <div
                                                                  className={
                                                                      'sub-title'
                                                                  }
                                                                  style={{
                                                                      padding:
                                                                          '2px 6px',
                                                                      borderRadius: 1000,
                                                                      fontSize:
                                                                          '12px',
                                                                      backgroundColor:
                                                                          subTask?.status ===
                                                                          'To Do'
                                                                              ? '#d5d5d5'
                                                                              : subTask?.status ===
                                                                                'Done'
                                                                              ? '#C8E5CE'
                                                                              : colorTaskRandom[
                                                                                    (Math.random() *
                                                                                        colorTaskRandom.length) |
                                                                                        0
                                                                                ],
                                                                  }}
                                                              >
                                                                  {
                                                                      subTask?.status
                                                                  }
                                                              </div>
                                                              <div>
                                                                  {subTask?.assigneeId ? (
                                                                      <div>
                                                                          <Tooltip
                                                                              title={
                                                                                  subTask
                                                                                      ?.assigneeId
                                                                                      ?.length >
                                                                                  0
                                                                                      ? subTask
                                                                                            ?.assigneeId[0]
                                                                                            ?.name
                                                                                      : Object.keys(
                                                                                            subTask?.assigneeId
                                                                                        )
                                                                                            ?.length >
                                                                                        0
                                                                                      ? subTask
                                                                                            ?.assigneeId
                                                                                            ?.name
                                                                                      : 'Unassigned'
                                                                              }
                                                                          >
                                                                              {subTask
                                                                                  ?.assigneeId
                                                                                  ?.length >
                                                                                  0 ||
                                                                              Object.keys(
                                                                                  subTask?.assigneeId
                                                                              )
                                                                                  ?.length >
                                                                                  0 ? (
                                                                                  subTask
                                                                                      ?.assigneeId[0]
                                                                                      ?.profilePicture ? (
                                                                                      <Image
                                                                                          src={
                                                                                              subTask
                                                                                                  ?.assigneeId[0]
                                                                                                  ?.profilePicture
                                                                                          }
                                                                                          height={
                                                                                              '24px'
                                                                                          }
                                                                                          width={
                                                                                              '24px'
                                                                                          }
                                                                                          preview={
                                                                                              false
                                                                                          }
                                                                                          style={{
                                                                                              borderRadius:
                                                                                                  '50%',
                                                                                          }}
                                                                                      />
                                                                                  ) : (
                                                                                      <Avatar
                                                                                          size={
                                                                                              'small'
                                                                                          }
                                                                                          style={{
                                                                                              cursor: 'pointer',
                                                                                              backgroundColor:
                                                                                                  'rgb(0 10 129 / 25%)',
                                                                                              height: '24px',
                                                                                              width: '24px',
                                                                                              minWidth:
                                                                                                  '24px',
                                                                                          }}
                                                                                          className="active-tooltip"
                                                                                      >
                                                                                          {subTask
                                                                                              ?.assigneeId
                                                                                              ?.length >
                                                                                              0 &&
                                                                                              subTask?.assigneeId[0]?.name[0].toUpperCase()}
                                                                                          {subTask
                                                                                              ?.assigneeId
                                                                                              ?.length >
                                                                                              0 &&
                                                                                              subTask?.assigneeId[0]?.name.split(
                                                                                                  ' '
                                                                                              )[1] !==
                                                                                                  undefined &&
                                                                                              subTask?.assigneeId[0]?.name.split(
                                                                                                  ' '
                                                                                              )[1] !==
                                                                                                  '' &&
                                                                                              subTask?.assigneeId[0]?.name
                                                                                                  .split(
                                                                                                      ' '
                                                                                                  )[1][0]
                                                                                                  .toUpperCase()}
                                                                                      </Avatar>
                                                                                  )
                                                                              ) : (
                                                                                  <Avatar
                                                                                      size={
                                                                                          'small'
                                                                                      }
                                                                                  >
                                                                                      <UserOutlined />
                                                                                  </Avatar>
                                                                              )}
                                                                          </Tooltip>
                                                                          {/* </Popover> */}
                                                                      </div>
                                                                  ) : (
                                                                      <Tooltip
                                                                          title={
                                                                              'Unassigned'
                                                                          }
                                                                      >
                                                                          <Avatar
                                                                              size={
                                                                                  'default'
                                                                              }
                                                                              icon={
                                                                                  <UserOutlined />
                                                                              }
                                                                          />
                                                                      </Tooltip>
                                                                  )}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </Row>
                                          ))}
                                  </>
                              ))}
                    </div>
                </Col>
                <Col span={14}>
                    <div
                        id={'timeline-container'}
                        style={{
                            padding: 2,
                            overflow: 'scroll',
                            position: 'relative',
                            height: 'calc(100vh - 150px)',
                            backgroundSize: '1px 40px',
                            backgroundImage:
                                'linear-gradient(to bottom, #eff2ff 2px, transparent 1px)',
                        }}
                    >
                        <Row gutter={8} wrap={false}>
                            {slots.map((slot, index) => (
                                <Col key={index} span={3}>
                                    <div className={styles.slots + ' slots'}>
                                        {slot}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        <div style={{ marginTop: 10 }} />

                        {status === 'loading'
                            ? Array.from({ length: count }, (_, index) => (
                                  <div className={styles.roadMapTaskSkeleton}>
                                      <div
                                          style={{
                                              position: 'relative',
                                          }}
                                      >
                                          <Skeleton.Input
                                              key={index}
                                              active={true}
                                              size={'default'}
                                              style={{
                                                  width: '10%',
                                              }}
                                              rootClassName="roadmapSkeletonCalendar"
                                          />
                                          <div
                                              style={{
                                                  position: 'absolute',
                                                  top: -4,
                                                  left: -20,
                                              }}
                                          >
                                              <Skeleton.Avatar
                                                  active={true}
                                                  size={32}
                                                  shape={'circle'}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : slotWidth &&
                              roadmapTasks?.length > 0 &&
                              roadmapTasks.map((data, index) => {
                                  return (
                                      <>
                                          {data?.start === null ? (
                                              <div
                                                  style={{
                                                      marginBottom: '17px',
                                                      width: '100%',
                                                  }}
                                              >
                                                  <Text
                                                      style={{
                                                          fontStyle: 'italic',
                                                      }}
                                                  >
                                                      Please Select Start and
                                                      End Date
                                                  </Text>
                                              </div>
                                          ) : (
                                              <RoadmapDraggable
                                                  data={data}
                                                  keyIndex={index}
                                                  isParent={true}
                                              />
                                          )}
                                          {data?.subTasks?.length > 0 &&
                                              data?.subTasks.map(
                                                  (subTask, keyIndex) => (
                                                      <>
                                                          <RoadmapDraggable
                                                              data={subTask}
                                                              keyIndex={
                                                                  keyIndex
                                                              }
                                                              isParent={false}
                                                          />
                                                      </>
                                                  )
                                              )}
                                      </>
                                  )
                              })}
                    </div>
                </Col>
            </Row>

            <Drawer
                placement="right"
                size={'large'}
                style={{
                    height: '100vh',
                }}
                styles={{
                    body: {
                        padding: '0px',
                    },
                }}
                rootClassName="taskHeader"
                onClose={() => {
                    dispatch(switchFormModal({ open: false }))
                    dispatch(clearTaskFileUpload())
                    dispatch(taskFileError(''))
                }}
                open={formModal}
            >
                <DrawerTaskForm
                    assigneeList={filteredAssignees}
                    onCancel={() => {
                        dispatch(switchFormModal({ open: false }))
                    }}
                    listTaskCall={() => getRoadMapTasks()}
                />
            </Drawer>
        </>
    )
}

export default RoadMap
